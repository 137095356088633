export default function() {
  const get = path => this.$path(path, this.$store.state)

  return [
    {
      component: 'Section',
      props: {
        width: 'full',
        class: 'gallery',
        style: 'padding: 0.3rem;',
      },
      blocks: {
        default: [
          {
            component: 'Gallery',
            data: {
              content: get('Project.item.media.gallery'),
            },
            props: {
              gap: '0.3rem',
              columns: 3,
              variant: 'gallery',
            },
          },
        ],
      },
    },
  ]
}
